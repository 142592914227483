/* ---------------- Custom Palette ---------------- */
/* For use in src/lib/core/theming/_palette.scss */
$primary: (
    50 : #e0f2f6,
    100 : #b3dee9,
    200 : #80c8db,
    300 : #4db1cc,
    400 : #26a1c1,
    500 : #0090b6,
    600 : #0088af,
    700 : #007da6,
    800 : #00739e,
    900 : #00618e,
    A100 : #bae5ff,
    A200 : #87d2ff,
    A400 : #54bfff,
    A700 : #3bb6ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$accent: (
    50: #fefdf7,
    100: #fdfbeb,
    200: #fcf8de,
    300: #fbf5d0,
    400: #faf3c6,
    500: #f9f1bc,
    600: #f8efb6,
    700: #f7edad,
    800: #f6eba5,
    900: #f5e797,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000)
);

$warn: (
    50 : #fdeeee,
    100 : #fad5d6,
    200 : #f6b9ba,
    300 : #f29d9e,
    400 : #f0888a,
    500 : #ed7375,
    600 : #eb6b6d,
    700 : #e86062,
    800 : #e55658,
    900 : #e04345,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffcdcd,
    A700 : #ffb3b4,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* ---------------- / Custom Palette ---------------- */