@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, "primary");

    .light {
        .mat-mdc-card {
            border: 1px solid #EAECF0;
            border-radius: 8px;
        }

        .mat-mdc-card-content {
            padding: 24px;
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }
}