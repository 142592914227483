@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, "primary");
    $warn-palette: map.get($color-config, "warn");

    .light .mat-mdc-chip {

        // Default Value
        .icon-chip-label {
            color: mat.get-color-from-palette($primary-palette, 500);
        }

        // Primary Color
        &.mat-primary-color {
            .icon-chip-label {
                color: mat.get-color-from-palette($primary-palette, 500);
            }
        }

        // Warn Color
        &.mat-warn-color {
            .icon-chip-label {
                color: mat.get-color-from-palette($warn-palette, 500);
            }
        }

        // Grey Color
        &.mat-grey-color {
            .icon-chip-label {
                color: #667085;
            }
        }

        // Green Color
        &.mat-green-color {
            .icon-chip-label {
                color: #70BF85;
            }
        }

        // Warning Color
        &.mat-warning-color {
            .icon-chip-label {
                color: #fd7e14;
            }
        }

    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }
}