// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use 'themes/theme.scss' as custom-theme;
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@use "./themes/components/button-theme" as custom-mat-button;
@use "./themes/components/card-theme" as custom-mat-card;
@use "./themes/components/badge-theme" as custom-mat-badge;
@use "./themes/components/chip-theme" as custom-mat-chip;
@include mat.core();

/* ---------------- Custom Font  ---------------- */

@font-face {
  font-family: "Urbanist";
  font-weight: 200;
  src: url("assets/fonts/Urbanist-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-weight: 300;
  src: url("assets/fonts/Urbanist-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-weight: 500;
  src: url("assets/fonts/Urbanist-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-weight: 600;
  src: url("assets/fonts/Urbanist-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-weight: 700;
  src: url("assets/fonts/Urbanist-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-weight: 800;
  src: url("assets/fonts/Urbanist-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-weight: 900;
  src: url("assets/fonts/Urbanist-Black.ttf") format("truetype");
}

/* ---------------- / Custom Font  ---------------- */


// Set Primary / Accent / Warn from custom colors set in themes/theme.scsss
$checkop-angular-primary: mat.define-palette(custom-theme.$primary);
$checkop-angular-accent: mat.define-palette(mat.$indigo-palette); //mat.define-palette(mat.$pink-palette, A200, A100, A400);
$checkop-angular-warn: mat.define-palette(custom-theme.$warn);

// Set Global Font
$custom-typography: mat.define-typography-config($font-family: "Urbanist");
@include mat.all-component-typographies($custom-typography);

/* -------------------------------- Custom Theme -------------------------------- */
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

// LIGHT THEME
$checkop-angular-light-theme: mat.define-light-theme((color: (primary: $checkop-angular-primary,
        accent: $checkop-angular-accent,
        warn: $checkop-angular-warn,
      ),
      //  typography: $custom-typography,
      density: -1 // Default 0
    ));

$checkop-angular-dark-theme: mat.define-dark-theme((color: (primary: $checkop-angular-primary,
        accent: $checkop-angular-accent,
        warn: $checkop-angular-warn,
        foreground: mat.get-color-from-palette($checkop-angular-primary, 700), // Adjust the contrast value as needed
      ),
      //   typography: $custom-typography,
      density: 0 // Default 0
    ));


/* -------------------------------- / Custom Theme -------------------------------- */

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
.light {
  @include mat.core-theme($checkop-angular-light-theme);
  @include mat.all-component-themes($checkop-angular-light-theme);

  a {
    color: #000;
  }

  a:hover {
    color: mat.get-color-from-palette(custom-theme.$primary, 500);
  }
}

.dark {
  @include mat.core-theme($checkop-angular-dark-theme);
  @include mat.all-component-themes($checkop-angular-dark-theme);

  // BUG:I don't know why but foreground text not work so I have to do that
  span,
  label,
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fafafa;
  }

  a:hover {
    color: mat.get-color-from-palette(custom-theme.$warn, 500);
  }
}


/* -------------------------------- Custom Componants Style -------------------------------- */
@include custom-mat-button.theme($checkop-angular-light-theme);
@include custom-mat-card.theme($checkop-angular-light-theme);
@include custom-mat-badge.theme($checkop-angular-light-theme);
@include custom-mat-chip.theme($checkop-angular-light-theme);
/* -------------------------------- / Custom Componants Style -------------------------------- */


/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Urbanist", sans-serif;
}

body.dark {
  background-color: #303030;

  .mat-toolbar-single-row {
    background-color: #424242;
  }
}

body.light .mat-toolbar-single-row {
  background-color: white;
}


// Custom Primary Class SCSS
.mat-primary-background {
  background-color: mat.get-color-from-palette($checkop-angular-primary, 500) !important;
}

.mat-primary-background-pastel {
  background-color: rgba(mat.get-color-from-palette($checkop-angular-primary, 500), 0.2) !important;
}

.mat-primary-background-light-pastel {
  background-color: rgba(mat.get-color-from-palette($checkop-angular-primary, 500), 0.1) !important;
}

.mat-primary-color {
  color: mat.get-color-from-palette($checkop-angular-primary, 500);
}

.mat-green-color {
  color: #70BF85;
}

.mat-green-background-pastel {
  background-color: rgba(#70BF85, 0.2) !important;
}

.mat-grey-color {
  color: #667085;
}

.mat-grey-background-pastel {
  background-color: rgba(#667085, 0.2) !important;
}

.mat-grey-background-light-pastel {
  background-color: rgba(#667085, 0.1) !important;
}

.mat-warning-color {
  color: #fd7e14;
}

.mat-warning-background-light-pastel {
  background-color: rgba(#fd7e14, 0.1) !important;
}

.mat-warn-color {
  color: mat.get-color-from-palette($checkop-angular-warn, 500);
}

.mat-warn-background-pastel {
  background-color: rgba(mat.get-color-from-palette($checkop-angular-warn, 500), 0.2) !important;
}

.mat-warn-background-light-pastel {
  background-color: rgba(mat.get-color-from-palette($checkop-angular-warn, 500), 0.1) !important;
}

// SnackBar
.mat-mdc-snack-bar-container {
  &.snackbar-theme {
    --mdc-snackbar-container-color: #333;
    --mat-snack-bar-button-color: #f7acba;
    // --mdc-snackbar-supporting-text-color: #fff
  }
}

// Custom SCSS Card Class
h2.title-mat-card {
  margin-top: 0.25em;
  margin-block-end: 1em;
  ;
}

.mat-mdc-form-field-error {
  margin-top: -3px;

}


.arianne-thread {
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 14px;

  a {
    white-space: nowrap;
    width: fit-content;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a,
  svg-icon {
    color: rgb(102, 102, 102);

  }

  a.last-link {
    text-decoration: none;
  }

}